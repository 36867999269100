//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { CsrService } from '../csr.service';
import { AccountService } from '../../account/account.service';
import { DepartmentService } from '../../csr/department/department.service';
import { IssueTypeService } from '../../csr/issue-type/issue-type.service';
import { ProjectService } from '../../project/project.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';
import { ImageService } from '../image/image.service';

//Core
import { Csr } from '../csr';
import { Department } from '../../csr/department/department';
import { IssueType } from '../../csr/issue-type/issue-type';
import { Project } from '../../project/project';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';


interface ObjectProperties {
  name: string;
  code: string;
}

@Component({
  selector: 'app-csr-add',
  templateUrl: './csr-add.component.html'
})
export class CsrAddComponent implements OnInit {

  public title = 'Add New CSR';
  public issue: Csr;
  public issueId = 0;
  public id = 0;
  public departmentId = 0;
  public issueTypes: IssueType[] = [];
  public issueType: IssueType;
  public projects: Project[] = [];
  public project: Project;
  public department: Department;
  public ProgramTitle = 'Program';
  public selectPriority: ObjectProperties;
  public Sending = false;
  public priorities: ObjectProperties[];
  private two = 2;
  public fileImage: any;
  public userLogged: User;
  public visitId: String = '';
  public issueTypesValidator = true;
  public employeeNameValidator = false;
  @ViewChild('issueform', { static: false }) public issueform: NgForm;

  constructor(private readonly csrService: CsrService,
    private readonly accountService: AccountService,
    private readonly projectService: ProjectService,
    private readonly departmentService: DepartmentService,
    private readonly issueTypeService: IssueTypeService,
    private readonly imageService: ImageService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: AppBreadcrumbService  ) {

    this.priorities = [
      { name: 'Low', code: 'Low' },
      { name: 'Medium', code: 'Medium' },
      { name: 'High', code: 'High' }
    ];

  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getUserLogged();

      const dt = new Date();
      this.issue = { id: 0, dateSubmitted: dt } as Csr;

      this.route.params.subscribe(
        (params: any) => {
          this.id = +params['id'];
          this.departmentId = +params['departmentId'];

          if (this.id === 1) {

            this.issueType = this.issueTypes.filter((x) => x.description === 'Security issue')[0];
            this.project = this.projects.filter((x) => x.projectname === 'Security Related')[0];
          }

          this.getDepartment();
        }
      );

      this.getProjects();

      this.breadcrumbService.setItems([
        { label: 'csr' },
        { label: 'deparment', routerLink: [`/csr/department/${this.two}`] },
        { label: 'add new', routerLink: [`/csr/csr-add/${this.id}/${this.departmentId}`] }
      ]);
    }
    else {
      this.router.navigate(['/account/login']);
    }

  }

  public getDepartment(): void {

    this.departmentService.getDepartment(this.departmentId)
      .subscribe((res: any) => this.department = res as Department,
        (error: any) => {

          this.messageService.add({ key: 'csraddkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          this.title = `Add New CSR [${this.department.name}]`;

          this.getIssueTypesPerDepartment();

          if (this.department.name === 'Laboratory Instrumentation') {
            this.ProgramTitle = 'Instrument';
          }
          else if (this.department.name === 'Building Facility & Maintenance') {
            this.ProgramTitle = 'Location';
          }
          else if (this.department.name === 'Phone and Outbound Networks') {
            this.ProgramTitle = 'Location';
          }
          else if (this.department.name === 'Reagents Issues') {
            this.ProgramTitle = 'Department';
          }
          else {
            this.ProgramTitle = 'Program';
          }

        }
      );
  }

  public getIssueTypesPerDepartment(): void {

    this.issueTypeService.getIssueTypesPerDepartment(this.departmentId)
      .subscribe(res => {
        this.issueTypes = res as IssueType[];

        if (this.id === 1) {

          this.issueType = this.issueTypes.filter((x) => x.description === 'Security issue')[0];
        }
      },
        (error: any) => {

          this.messageService.add({ key: 'csraddkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public getProjects(): void {

    this.projectService.getProjectsPerDepartment(this.departmentId)
      .subscribe((res: any) => {
        this.projects = res as Project[];

        if (this.id === 1) {

          this.project = this.projects.filter((x) => x.projectname === 'Security Related')[0];
        }
      },
        (error: any) => {

          this.messageService.add({ key: 'csraddkey', severity: 'error', summary: 'Error', detail: error });

          if (error === 'Unauthorized') {
            this.router.navigate(['/account/login']);
          }

        }
      );
  }

  public onSubmit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getUserLogged();
      this.issue.id = 0;
      this.issue.issueBy = this.userLogged.id;
      this.issue.status = 'New request';
      this.issue.projectId = this.project.id;
      this.issue.project = this.project;
      this.issue.priority = this.selectPriority.code;
      this.issue.issueType = this.issueType.description;
      if (this.visitId !== '') {
        this.issue.description = `${this.issue.description}<br><p>Visit ID: ${this.visitId}</p><br><p>CSR by: ${this.issue.employeeName}</p>`;
      } else {
        this.issue.description = `${this.issue.description}<br><p>CSR by: ${this.issue.employeeName}</p>`;
      }      
      this.issue.departmentId = this.department.id;
      this.issue.eventsSchedule = false;
      this.issue.strDateSubmitted = `${this.issue.dateSubmitted.toLocaleDateString('en-US')} ${this.issue.dateSubmitted.toLocaleTimeString('en-US')}`; // this.issue.dateSubmitted.toLocaleString();

      this.sendingEMail(true);

      this.csrService.addCsr(this.issue)
        .subscribe((res: any) => {

          this.issueId = res as number;          
        },
          (error: any) => {

            this.sendingEMail(false);
            this.messageService.add({ key: 'csraddkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.sendingEMail(false);
            this.issueform.reset();

            if (this.fileImage !== undefined) {
              this.onUploadImage(this.fileImage);
            } else {
              this.router.navigate([`/csr/csr-details/${this.issueId}`]);
            }
            
          });
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public sendingEMail(value: boolean): void {

    if (value === true) {
      this.Sending = true;
    }
    else {
      this.Sending = false;
    }
  }

  public fileChangeEvent(fileInput: any): void {
    //let files = <Array<File>>fileInput.target.files;
    this.fileImage = fileInput.target.files[0];

    if (this.fileImage !== undefined) {
      this.issueTypesValidator = true;
    }
  }

  public onUploadImage(file: any): void {   

    const formData = new FormData();

    formData.append(file.name, file);
    formData.append('issueid', this.issueId.toString());
    formData.append('userid', this.userLogged.id);

    this.imageService.uploadImage(formData)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'csraddkey', severity: 'success', summary: 'Image', detail: 'File upload successful' });
      },
        (error: any) => {

          this.messageService.add({ key: 'csraddkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.router.navigate([`/csr/csr-details/${this.issueId}`]);
        });
  }

  public onChangeIssueTypes(event: any): void {

    const issueType = event.description;

    if (issueType === 'User Edit / New User') {
      this.issueTypesValidator = false;
    } else if (issueType === 'Status Change') {
      this.issueTypesValidator = false;
    } else {
      this.issueTypesValidator = true;
    }
  }

  public onKeyEmployeeName(value: string): void {

    if (value.length < 6) {
      this.employeeNameValidator = false;
    } else {
      this.employeeNameValidator = true;
    }

  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUserLogged(): User {

    return this.accountService.getLoggedInUser();
  }

}
