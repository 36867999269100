import {Component} from '@angular/core';
import { AppComponent } from '../app.component';
import { AppMainComponent } from './app.main.component';

//Services
import { CsrService } from '../csr/csr.service';

//Core
import { GlobalCsrData } from '../csr/global-csr-data';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {

  public globalCsrData: GlobalCsrData[] = [];

  constructor(public appMain: AppMainComponent, public app: AppComponent, private readonly csrService: CsrService) {
    this.getGlobalCsrData();
  }

  public getGlobalCsrData(): void {

    this.csrService.getGlobalCsrData()
      .subscribe((res: any) => {
        this.globalCsrData = res as GlobalCsrData[];
      },
        (error: any) => {
          console.log(error);
        });
  }

}
