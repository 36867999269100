import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from '../app.component';
import { AppMainComponent } from './app.main.component';
import { Router } from '@angular/router';
// https://www.npmjs.com/package/@aspnet/signalr   *** npm i @aspnet/signalr ****
import { HubConnectionBuilder, HubConnection, HttpTransportType } from '@aspnet/signalr';

//Services
import { CsrService } from '../csr/csr.service';
import { CommentsAssignedToMyService } from '../csr/comments-assigned-to-my.service';
import { AccountService } from '../account/account.service';
import { UserLoggedInService } from '../account/user-logged-in.service';
import { DepartmentService } from '../csr/department/department.service';

//Model
import { Comment } from '../csr/comment/comment';
import { User } from '../account/model/user';
import { Csr } from '../csr/csr';
import { NewCsrsCounter } from '../csr/new-csrs-counter';
import { MessageNewCsr } from '../signalr/message-new-csr'

//Primeng
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [
        animate('.1s linear', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class AppTopbarComponent implements OnInit {

  public comments: Comment[] = [];
  public userLogged: User = {} as User;
  public newCsrsCounters: NewCsrsCounter[] = [];
  public newCsrQty = 0;
  public commentEmpty = false;
  public commentsQty = 0;
  public cols: any[];
  public loading: boolean;
  public issue: Csr;
  public isAdmin = false;
  @ViewChild('boxSearchCsr') boxSearchCsr: ElementRef;
  public messageNewCsr: MessageNewCsr = {} as MessageNewCsr;
  public hubConnection!: HubConnection;

  constructor(public appMain: AppMainComponent, public app: AppComponent,
    private readonly accountService: AccountService,
    private readonly csrService: CsrService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly departmentService: DepartmentService,
    private readonly router: Router,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly messageService: MessageService  ) { }

  public ngOnInit(): void {

    this.userLogged = this.getUser();
    this.updateCommentsByService();
    this.getComments();
    this.getNewCSRs();
    this.listenerNewCsr();
  }

  public listenerNewCsr() {

    let builder = new HubConnectionBuilder();
    //this.hubConnection = builder.withUrl("http://localhost:57232/newcsr",
    this.hubConnection = builder.withUrl("https://cmr.labwarehousepr.com/newcsr",
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      }).build();

    this.hubConnection.start()
      .then(() => console.log("Connection started"))
      .catch(error => console.log(error));

    this.hubConnection.on("BroadcastMessage", (message) => {

      this.messageNewCsr = message;
      
      if (this.messageNewCsr.actionType === 'delete') {
        this.bellRingSound();
        //this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Deleted Csr', detail: `Csr #. ${this.messageNewCsr.csrId}, ${this.messageNewCsr.subject}` });
        setTimeout(() => {
          this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Deleted Csr', detail: `Csr #. ${this.messageNewCsr.csrId}, ${this.messageNewCsr.subject}`, life: 20000 });
        }, 1000);
      } else if (this.messageNewCsr.actionType === 'put') {
        this.bellRingSound();
        let arrays: string[] = [`Csr #. ${this.messageNewCsr.csrId}`, `Subject: ${this.messageNewCsr.subject}`, `Project: ${this.messageNewCsr.projectName}`, `${this.messageNewCsr.clientName}`];
        //this.messageService.add({ key: 'topbarkey', severity: 'info', summary: 'Updated Csr', detail: arrays.join('\n') });
        setTimeout(() => {
          this.messageService.add({ key: 'topbarkey', severity: 'info', summary: 'Updated Csr', detail: arrays.join('\n'), life: 20000 });
        }, 1000);
      } else {
        this.newCsrSound();
        let arrays: string[] = [`Csr #. ${this.messageNewCsr.csrId}`, `Subject: ${this.messageNewCsr.subject}`, `Project: ${this.messageNewCsr.projectName}`, `Client: ${this.messageNewCsr.clientName}`];
        //this.messageService.add({ key: 'topbarkey', severity: 'success', summary: 'New Csr', detail: arrays.join('\n') });
        setTimeout(() => {
          this.messageService.add({ key: 'topbarkey', severity: 'success', summary: 'New Csr', detail: arrays.join('\n'), life: 25000 });
        }, 1000);
      }

      this.getNewCSRs();
    });

  }

  public newCsrSound() {
    try {
      let audio = new Audio();
      audio.src = '/assets/you-have-a-new-csr.mp3';
      audio.load();
      audio.play();
    }
    catch { }
  }

  public bellRingSound() {
    try {
      let audio = new Audio();
      audio.src = '/assets/bell-ring.mp3';
      audio.load();
      audio.play();
    }
    catch { }
  }

  public openCommentsTable(event: any, overlaypanel: OverlayPanel): void {

    overlaypanel.toggle(event);
  }

  public selectCsr(issueid: number, overlaypanel: OverlayPanel): void {

    overlaypanel.hide();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([`/csr/csr-details/${issueid}`]);
  }

  public getComments(): void {

    if (this.isUserLoggedIn()) {

      this.csrService.getCommentsAssignedToMy()
        .subscribe((res: any) => {

          this.comments = res as Comment[];

          if (this.comments.length > 0) {
            this.commentEmpty = true;
            this.commentsQty = this.comments.length;
          }
          else {
            this.commentEmpty = false;
            this.commentsQty = 0;
          }

          this.loadColumns();

          this.loading = false;

          this.commentsAssignedToMyService.updateComments(this.comments);
        },
          (error: any) => {

            this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: error });

          });
    }
  }

  public updateCommentsByService(): void {

    this.commentsAssignedToMyService.scast.subscribe(comments => {

      this.comments = comments;

      if (this.comments.length > 0) {
        this.commentEmpty = true;
        this.commentsQty = this.comments.length;
      }
      else {
        this.commentEmpty = false;
        this.commentsQty = 0;
      }

      this.loadColumns();

      this.loading = false;
    });

  }

  public loadColumns(): void {

    this.cols = [
      { field: 'issueId', header: 'CSR #' },
      { field: 'id', header: 'Comment #' },
      { field: 'commentDate', header: 'Date' },
      { field: 'commentBy', header: 'Comment by' }
    ];

    this.loading = true;
  }

  public onSearchCsr(value: string): void {

    if (!Number(value)) {
      this.boxSearchCsr.nativeElement.value = '';
      this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: 'Please enter only numbers.' });
    } else {
      this.csrService.getCsr(+value)
        .subscribe((res: any) => this.issue = res as Csr,
          (error: any) => {

            this.boxSearchCsr.nativeElement.value = '';
            this.messageService.add({ key: 'topbarkey', severity: 'warn', summary: 'Not found', detail: error });
          },
          () => {

            this.boxSearchCsr.nativeElement.value = '';

            if (this.issue !== undefined) {         
              this.redirectTo(`/csr/csr-details/${this.issue.id}`);
            }

          });
    }
  }

  public redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  public goToNewCSRs(departmentId: number): void {
    this.departmentService.setDepartmentID(departmentId);
    this.redirectTo('/csr/quick-search/1/0');
  }

  public getNewCSRs(): void {

    if (this.isUserLoggedIn()) {

      this.csrService.getNewCsrs(this.userLogged.id)
        .subscribe((res: any) => {

          this.newCsrsCounters = res as NewCsrsCounter[];          
        },
          (error: any) => {

            this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: error });

          },
          () => {
            this.newCsrQty = 0;
            for (var csr of this.newCsrsCounters) {
              this.newCsrQty += csr.quantity;
            }

          });
    }
  }

  public getUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    const userRoles = userLogged.roles.find(row => row === 'Admin' || row === 'SuperAdmin');
    if (userRoles !== undefined) {
      this.isAdmin = true;
    }
    else {
      this.isAdmin = false;
    }

    return userLogged;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
