import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  topbarTheme: string = 'bluegrey';

  menuTheme: string = 'light';

  layoutMode: string = 'light';

  menuMode: string = 'horizontal';

  inlineMenuPosition: string = 'bottom';

  inputStyle: string = 'filled';

  ripple: boolean = true;

  isRTL: boolean = false;

  constructor(public primengConfig: PrimeNGConfig) {
  }
  ngOnInit() {

    this.primengConfig.ripple = true;
  }
  
}
